$(function () {
  $('.open-comment').on('click', function (e) {
    e.preventDefault();
    if ($(this).find('span').hasClass('arrow-down'))
      $(this).find('span').removeClass('arrow-down').addClass('arrow-up');
    else
      $(this).find('span').removeClass('arrow-up').addClass('arrow-down');
    $id = $(this).data('q2aid');
    $('#answers-list-' + $id + ' li:not(.sublist)').each(function () {
      $(this).not('.first').toggleClass('hidden');
    });
  });
  $('.vote-up').on('click', function (e) {
    e.preventDefault();
    $id = $(this).data('postid');
    $cusid = $(this).data('cusid');
    cookie_name = 'vote_' + btoa($id + ':' + $cusid);
    if (Cookies.get(cookie_name) === undefined || Cookies.get(cookie_name) === 'down') {
      $('.vote-result-' + $id).html('<i class="fa fa-spinner fa-spin icon"></i>');
      // Ajax
      $.ajax({
        type: 'POST',
        url: ajax_url,
        data: {
          way: 'up',
          vote: Cookies.get(cookie_name) === 'down' ? 2 : 1,
          id: $id,
          ajax: true,
          action: 'voteQuestion'
        },
        dataType: 'json',
        success: function (json) {
          Cookies.set(cookie_name, 'up');
          $('.vote-result-' + $id).removeClass('down').addClass('up').text(json.vote);
        }
      });
    }
  });
  $('.vote-down').on('click', function (e) {
    e.preventDefault();
    $id = $(this).data('postid');
    $cusid = $(this).data('cusid');
    cookie_name = 'vote_' + btoa($id + ':' + $cusid);
    if (Cookies.get(cookie_name) === undefined || Cookies.get(cookie_name) === 'up') {
      $('.vote-result-' + $id).html('<i class="fa fa-spinner fa-spin icon"></i>');
      // Ajax
      $.ajax({
        type: 'POST',
        url: ajax_url,
        data: {
          way: 'down',
          vote: Cookies.get(cookie_name) === 'up' ? 2 : 1,
          id: $id,
          ajax: true,
          action: 'voteQuestion'
        },
        dataType: 'json',
        success: function (json) {
          Cookies.set(cookie_name, 'down');
          $('.vote-result-' + $id).removeClass('up').addClass('down').text(json.vote);
        }
      });
    }
  });
  $('.stat-item-up').on('click', function (e) {
    e.preventDefault();
    $id = $(this).data('postid');
    $cusid = $(this).data('cusid');
    cookie_name = 'stat_' + btoa($id + ':' + $cusid);
    if (Cookies.get(cookie_name) === undefined) {
      $('.stat-item-up-result-' + $id).html('<i class="fa fa-spinner fa-spin icon"></i>');
      // Ajax
      $.ajax({
        type: 'POST',
        url: ajax_url,
        data: {
          way: 'up',
          id: $id,
          ajax: true,
          action: 'voteAnswer'
        },
        dataType: 'json',
        success: function (json) {
          Cookies.set(cookie_name, true);
          $('.stat-item-up-result-' + $id).text(json.vote);
        }
      });
    }
  });
  $('.stat-item-down').on('click', function (e) {
    e.preventDefault();
    $id = $(this).data('postid');
    $cusid = $(this).data('cusid');
    cookie_name = 'stat_' + btoa($id + ':' + $cusid);
    if (Cookies.get(cookie_name) === undefined) {
      $('.stat-item-down-result-' + $id).html('<i class="fa fa-spinner fa-spin icon"></i>');
      // Ajax
      $.ajax({
        type: 'POST',
        url: ajax_url,
        data: {
          way: 'down',
          id: $id,
          ajax: true,
          action: 'voteAnswer'
        },
        dataType: 'json',
        success: function (json) {
          Cookies.set(cookie_name, true);
          $('.stat-item-down-result-' + $id).text(json.vote);
        }
      });
    }
  });
  $('.q2a_answer_form').on('submit', function (e) {
    e.preventDefault();
    var form = $(this);
    // Ajax
    $.ajax({
      type: 'POST',
      url: ajax_url,
      data: form.serializeArray(),
      dataType: 'json',
      success: function (json) {
        if (!json.errors) {
          $('#answers-list-' + json.id_q2a_questions).append('<li class="comment ajax" id="answer-' + json.id_answer + '"> \t\t\t\t\t\t<p class="pull-left img-contain"> \t\t\t\t\t\t\t<img class="img-circle avatar" src="http://www.gravatar.com/avatar/' + json.email + '" alt="avatar"> \t\t\t\t\t\t</p> \t\t\t\t\t\t<div class="comment-body bbottom"> \t\t\t\t\t\t\t<div class="comment-heading"> \t\t\t\t\t\t\t\t<h4 class="user">' + json.cusname + '</h4> \t\t\t\t\t\t\t</div> \t\t\t\t\t\t\t<p class="pull-left">' + json.answer + '</p> \t\t\t\t\t\t\t<div class="pull-right stats"> \t\t\t\t\t\t\t\t<span>' + json.vote_text + '</span> \t\t\t\t\t\t\t\t<a href="#" class="btn btn-success stat-item stat-item-up" data-postid="' + json.id_answer + '" data-cusid="' + json.cusid + '"> \t\t\t\t\t\t\t\t\t<i class="fa fa-thumbs-up icon"></i> \t\t\t\t\t\t\t\t\t<span class="stat-item-up-result-' + json.id_answer + '">0</span> \t\t\t\t\t\t\t\t</a> \t\t\t\t\t\t\t\t<a href="#" class="btn btn-danger stat-item stat-item-down" data-postid="' + json.id_answer + '" data-cusid="' + json.cusid + '"> \t\t\t\t\t\t\t\t\t<i class="fa fa-thumbs-down icon"></i> \t\t\t\t\t\t\t\t\t<span class="stat-item-down-result-' + json.id_answer + '">0</span> \t\t\t\t\t\t\t\t</a> \t\t\t\t\t\t\t</div> \t\t\t\t\t\t\t<div class="clearfix"></div> \t\t\t\t\t\t</div> \t\t\t\t\t</li>');
          $.scrollTo('#answer-' + json.id_answer, 800);
        } else
          form.append('<br />' + json.errors);
      }
    });
  });
});